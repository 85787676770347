import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { BsShieldLock } from "react-icons/bs";
import { FiArrowUpRight, FiBriefcase, FiZap } from "react-icons/fi";

import { FSTIcon } from "../../components/FSTIcon";

interface CardProps {
  heading: string;
  description: string;
  icon: {
    name: string;
    ariaLabel: string;
  };
}

const iconMap: { [key: string]: IconType } = {
  FiZap,
  FiArrowUpRight,
  FiBriefcase,
  BsShieldLock,
};

const Card = ({ heading, description, icon }: CardProps) => {
  const IconComponent = iconMap[icon.name];
  return (
    <VStack align="center" spacing="20px">
      <FSTIcon
        withRing
        Icon={IconComponent}
        aria-label={icon.ariaLabel}
        iconFill="primary.800"
      />
      <VStack gap="8px">
        <Heading as="h2" fontSize="xl" fontWeight="semibold" textAlign="center">
          {heading}
        </Heading>
        <Text color="gray.500" fontSize="md" textAlign="center">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};

const features = {
  auctionTypes: {
    heading: "English, Dutch & Seller Price Auctions",
    description:
      "Tailor your fundraising style with versatile auction types - English, Dutch, and Seller Price.",
    icon: {
      name: "FiZap",
      ariaLabel: "zap icon",
    },
  },
  shareBuybacks: {
    heading: "Built-In Share Buybacks",
    description:
      "Boost value and adaptability with seamlessly integrated share buyback options.",
    icon: {
      name: "FiArrowUpRight",
      ariaLabel: "arrow up right icon",
    },
  },
  cashOuts: {
    heading: "Budget-Friendly Cash-Outs",
    description:
      "Maximize your earnings with a cost-effective cash-out feature.",
    icon: {
      name: "FiBriefcase",
      ariaLabel: "briefcase icon",
    },
  },
  blockChain: {
    heading: "Blockchain-Secured Transactions",
    description:
      "Leverage Celo blockchain for unparalleled security and transparency in all your fundraising activities.",
    icon: {
      name: "BsShieldLock",
      ariaLabel: "shield icon",
    },
  },
};

export default function Features() {
  return (
    <VStack
      gap={["48px", "64px"]}
      px={["16px", null, "96px"]}
      py={["64px", null, "96px"]}
    >
      <VStack gap="12px">
        <Heading color="primary.800" fontSize="md">
          Features
        </Heading>
        <Heading color="gray.800" fontSize={["2xl", "4xl"]} textAlign="center">
          Elevate Your Fundraising Game
        </Heading>
      </VStack>

      <Flex gap="32px" justify="center" wrap="wrap">
        {Object.values(features).map((feature, index) => (
          <Box
            key={index}
            marginBottom="32px"
            paddingX={["16px", "32px"]}
            width={["100%", "calc(50% - 32px)", "calc(33.333% - 32px)"]}
          >
            <Card
              description={feature.description}
              heading={feature.heading}
              icon={feature.icon}
            />
          </Box>
        ))}
      </Flex>
    </VStack>
  );
}
